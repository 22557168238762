:root {
 --main-bg-color: #8797B2;
 --title-bg-color: #ABC8C0;
 --footer-bg-color: #70566D;
 --button-color: #70566D;
 --shadow-color: #07090a;
}

* {
  box-sizing: border-box;
}

.container {
  width: min(100vw, 55vh);
  height: min(180vw, 100vh);
  margin: 0 auto;
  background-color: var(--button-color);
}

.play-field {
  width: 99%;
  height: 100%;
  padding: 5px 0px;
  background-color: var(--main-bg-color);
  margin: 0 auto;
  display: grid;
  gap: 3px;
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.grid-cols-9 {
  grid-template-columns: repeat(9, auto);
}

.grid-cols-16 {
  grid-template-columns: repeat(16, auto);
}

.grid-cols-24 {
  grid-template-columns: repeat(24, auto);
}

.buttons-field {
  width: 100%;
  height: 15%;
  background-color: var(--title-bg-color);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.buttons-field button {
  width: 20%;
  height: 40%;
  border-radius: 7px;
  color: white;
  background-color: var(--button-color);
}

.title-field {
  width: 100%;
  height: 20%;
  background-image: linear-gradient(rgba(0 ,0 ,0 ,0.5), rgba(0 ,0 ,0 ,0.5)), url('./title.jpeg');
  background-size: cover;
  background-position-y: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.footer-field {
  width: 100%;
  height: 5%;
  background-color: var(--footer-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell {
  box-shadow: 3px 3px var(--shadow-color);
  border-top: 2px white solid;
  border-left: 2px white solid;
}

.title {
  font-size: 28px;
  font-weight: bold;
  color: white;
}

.subtitle {
  font-size: 16px;
  margin-top: 4px;
  text-align: center;
  color: white;
}

.footer {
  color: white;
}

.symbol-1 {
  background-image: url('./symbols.svg');
  background-size: cover;
}

.symbol-2 {
  background-image: url('./symbols.svg');
  background-size: cover;
  background-position-x: 11%;
}

.symbol-3 {
  background-image: url('./symbols.svg');
  background-size: cover;
  background-position-x: 22%;
}

.symbol-4 {
  background-image: url('./symbols.svg');
  background-size: cover;
  background-position-x: 33%;
}

.symbol-5 {
  background-image: url('./symbols.svg');
  background-size: cover;
  background-position-x: 44%;
}

.symbol-6 {
  background-image: url('./symbols.svg');
  background-size: cover;
  background-position-x: 55%;
}

.symbol-7 {
  background-image: url('./symbols.svg');
  background-size: cover;
  background-position-x: 66%;
}

.symbol-8 {
  background-image: url('./symbols.svg');
  background-size: cover;
  background-position-x: 77%;
}

.symbol-flag {
  background-image: url('./symbols.svg');
  background-size: cover;
  background-position-x: 88%;
}

.symbol-bomb {
  background-image: url('./symbols.svg');
  background-size: cover;
  background-position-x: 99%;
}

.bg-red {
  background-color: red;
}

.dialog-mask {
  width: 100%;
  height: 100%;
  filter: brightness(50%);
  position: absolute;
  background: black;
  opacity: 0.8;
}

.dialog-message-area {
  position: absolute;
  top: 40%;
  width: 100%;
}

.dialog-message {
  font-size: 24px;
  font-weight: bold;
  border-radius: 5px;
  padding: 30px;
  background-color: white;
  max-width: 50%;
  margin: auto;
  text-align: center;
}

.play-field-container {
  position: relative;
  height: 60%;
}
